$thumb-height: 100px;
$thumb-height-medium: 125px;
$thumb-height-large: 150px;
$thumb-height-xl: 175px;

$krp-normal-size: 150px;
$ilp-normal-size: 150px;
$comp-normal-size: 125px;
$lti-normal-size: 100px;

$krp-lg-size: 175px;
$ilp-lg-size: 175px;
$comp-lg-size: 150px;
$lti-lg-size: 125px;

$krp-sm-size: 125px;
$ilp-sm-size: 125px;
$comp-sm-size: 100px;
$lti-sm-size: 100px;

.dashboard-project {
  width: $thumb-height;
  position: relative;
  //cursor: move;
  margin-left: #{$spacer * 0.25};
}

.dashboard-project-medium {
  width: $thumb-height-medium !important;
  position: relative;
  //cursor: move;
  margin-left: #{$spacer * 0.25};
}

.dashboard-project-xl {
  width: $thumb-height-xl !important;
  position: relative;
  //cursor: move;
  margin-left: #{$spacer * 0.25};
}

// Standard project type sizes
.dashboard-project-krp {
  width: $krp-normal-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.dashboard-project-ilp,
.dashboard-project-eyfs,
.dashboard-project-school,
.dashboard-project-wrm {
  width: $ilp-normal-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.dashboard-project-comp,
.dashboard-project-other {
  width: $comp-normal-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.dashboard-project-lti,
.dashboard-project-ltc {
  width: $lti-normal-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

// Large project type sizes
.lg-dashboard-project-krp {
  width: $krp-lg-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.lg-dashboard-project-ilp,
.lg-dashboard-project-eyfs,
.lg-dashboard-project-school,
.lg-dashboard-project-wrm {
  width: $ilp-lg-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.lg-dashboard-project-comp,
.lg-dashboard-project-other {
  width: $comp-lg-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.lg-dashboard-project-lti,
.lg-dashboard-project-ltc {
  width: $lti-lg-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

// small project type sizes
.sm-dashboard-project-krp {
  width: $krp-sm-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.sm-dashboard-project-ilp,
.sm-dashboard-project-eyfs,
.sm-dashboard-project-school,
.sm-dashboard-project-wrm {
  width: $ilp-sm-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.sm-dashboard-project-comp,
.sm-dashboard-project-other {
  width: $comp-sm-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}

.sm-dashboard-project-lti,
.sm-dashboard-project-ltc {
  width: $lti-sm-size;
  position: relative;
  margin-left: #{$spacer * 0.25};
}
