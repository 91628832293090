.activity-assessment-applied {
  color: $cs-covered;
}

.activity-assessment-not-applied {
  color: grey;
}

.activity-assessment-not-applied-readonly {
  color: red;
}

.activity-assessment-absent {
  color: red;
  opacity: 0.5;
}
