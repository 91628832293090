.app-container.product-quick-teach,
.app-container.unauthorised {

  .site-header {
    .logo {
      background-image: url(../../images/quick-teach-orange-no-slogan.svg);
    }

    .user-profile-button {
      background: $qtPrimary;
    }
  }

  .main-nav {
    > .nav-item {
      &.logo {
        height: 6.6rem;

        > a.nav-link {
          background: transparent url(../../images/quick-teach-orange-no-slogan.svg) no-repeat center center/75%;
        }
      }

      &.active-parent {
        border-color: $qtPrimary;

        .nav-link.active {
          background: $qtPrimary;
        }
      }
    }
  }

  &.main-sidebar-collapsed .main-sidebar {
    .main-nav {
      li.nav-item.logo {
        > a.nav-link {
          background-image: url(../../images/quick-teach-rocket.svg);
          transform: rotate(-30deg);

        }
      }
    }

    .sidebar-brandmark {
      display: block;
    }
  }
}

.route-quickTeach {
  .main-column {
    // allows position: sticky to work on filter col
    overflow: initial;
  }

  .header-main,
  .page-actions {
    background: $qtBackground;
    color: $white;
    border-bottom: none;
  }

  .quick-teach-view {
    padding: 0;
  }

  .quick-teach-search {
    background: $qtBackground;
    color: $white;
    padding: $spacer;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .quick-teach-main {
    padding: $spacer;

    .quick-teach-filter-col {
      flex: 0 1 auto;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    @include media-breakpoint-up(md) {
      .quick-teach-filters {
        position: sticky;
        top: 6rem;
      }
    }
  }
}
