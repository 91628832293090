.main-content {
  padding: $spacer;
  position: relative;

  &.full-width {
    padding-left: 0;
    padding-right: 0;
  }

  &.full-height {
    height: $full-height-page;
    padding: 0;

    @media print {
      height: auto;
      min-height: auto;
    }
  }

  //div {
  //    & > :not(.main-content-full-width) {
  //        margin: $spacer;
  //    }
  //}
}
