.curriculum-pro-narrative-terms {
  table {
    thead {
      th {
        vertical-align: top;
        background: $cs-pale-grey !important;
        border-left: 1px solid #dee2e6;
      }
    }

    td {
      border-left: 1px solid #dee2e6;
    }

    .header-col {
      width: 15% !important;
      min-width: 15% !important;
      max-width: 15% !important;
    }

    .data-col {
      width: 28% !important;
      min-width: 28% !important;
      max-width: 28% !important;
    }
  }
}
