form {
  transition: opacity 0.5s linear;

  &.form-loading {
    opacity: 0.35;
  }
}

label {
  font-family: $headings-font-family;
}

.form-control-borderless {
  border: none;
  outline: none;
  overflow: hidden;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}

.autosize-textarea {
  min-height: $input-height;
  height: auto;
}

.no-resize {
  resize: none;
}

.modified-input {
  border-color: #4DAB57 !important;
  background-color: #cfffe3 !important;
}
