@each $name, $colour in $assessments {
  @include text-emphasis-variant(".text-#{$name}", $colour, true);
}

@each $name, $colour in $bigIdeas {
  @include text-emphasis-variant(".text-#{$name}", $colour, true);
}

.text-disabled {
  color: gray;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-wrap-pretty {
  text-wrap: pretty;
}

.ws-normal {
  white-space: normal;
}

.ws-nowrap {
  white-space: nowrap;
}

.ws-pre-wrap {
  white-space: pre-wrap;
}

.break-word {
  word-wrap: break-word;
  word-break: break-word;
}

.line-height-sm {
  line-height: $line-height-sm;
}

.text-strike {
  text-decoration: line-through;
}

.text-blur {
  filter: blur(0.35em);
  pointer-events: none;
  user-select: none;
}
