.coverage-graphic {
  width: 28px;
  height: 0.7rem;
  border-radius: 0;
  margin: auto;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  display: inline-block;
}

.coverage-graphic-na {
  @extend .coverage-graphic;
  background: transparent;
  border-style: dotted;
  border-color: black;
}

.coverage-none {
  background: $coverage-none;
}

.coverage-low {
  background: $coverage-low;
}

.coverage-medium-low {
  background: $coverage-medium-low;
}

.coverage-medium-high {
  background: $coverage-medium-high;
}

.coverage-high {
  background: $coverage-high;
}

.curriculum-drop-down {
  width: 250px;

  label {
    width: 60px;
    margin: 0;
    text-align: left;
  }
}

.coverage-symbol-no-li {
  color: $cs-develop;
}

.coverage-symbol-no-prj {
  color: $cs-engage;
}

.coverage-symbol-optional {
  color: $cs-engage;
}

.coverage-symbol-prj-cover {
  color: $coverage-high;
}

.coverage-symbol-user-cover {
  color: $coverage-high;
}

.coverage-background {
  background: $coverage-high;
}

// This is class is required to keep consistent dimensions between rounded badges with variant 'success' and 'outline-success'
.covered-badge {
  border: 1px solid $coverage-high;
}

.gap-summary-header-bar {
  td {
    border: none;
    padding: 0 2px;
    margin: 0;
    background: $primary;
    color: white;

    label {
      margin-bottom: 0;
    }
  }

  th {
    border-width: 0;
  }
}

.coverage-summary-header-bar {
  border: none;
  padding: 0 2px;
  margin: 0;
  background: $primary;
  color: white;

  label {
    margin-bottom: 0;
  }
}

.progression-table {
  .skill-covered-selected {
    // TODO: use brand colour
    background: rgba(0, 255, 0, 0.1);
  }

  .skill-not-covered-selected {
    background: $cs-light-grey;
  }

  .skill-covered {
    // TODO: use brand colour
    background: rgba(0, 255, 0, 0.3);
  }

  .skill-not-covered {
    background: transparent;
  }
}
