.in-out-translate-fade-enter-active,
.in-out-translate-fade-leave-active {
  transition: all 0.15s;
}

.in-out-translate-fade-enter,
.in-out-translate-fade-leave-active {
  opacity: 0;
}

.in-out-translate-fade-enter {
  transform: translateX(100px);
}

.in-out-translate-fade-leave-active {
  transform: translateX(-100px);
}

.out-in-translate-fade-enter-active,
.out-in-translate-fade-leave-active {
  transition: all 0.15s;
}

.out-in-translate-fade-enter,
.out-in-translate-fade-leave-active {
  opacity: 0;
}

.out-in-translate-fade-enter {
  transform: translateX(-100px);
}

.out-in-translate-fade-leave-active {
  transform: translateX(100px);
}
