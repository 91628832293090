$sidebar-collapsed-width: 3.4rem !default;
$sidebar-open-width: 14.3rem !default;
$sidebar-mobile-width: 16rem !default;
$sidebar-indicator-width: 5px !default;
$sidebar-background: $gray-200 !default;
$sidebar-submenu-background: $gray-300 !default;

//$sidebar-nav-link-left: max(($spacer - $sidebar-indicator-width), 0);
//$diff: ($spacer - $sidebar-indicator-width);
//$sidebar-nav-link-left: max(10, 0);
$sidebar-nav-link-left: 0.5rem !default;

$customItemColours: (
  intent: (light: #FFB66588,
    normal: $cs-engage,
    dark: #854600,
  ),
  delivery: (light: #BF569688,
    normal: #AF4C75,
    dark: #530032,
  ),
  impact: (light: #4AAFBE88,
    normal: #538EAA,
    dark: #015865,
  ),
  pro: (light: #8599b6,
    normal: #23395B,
    dark: #4e6485,
  ),
);

.main-wrap {
  position: relative;
  display: flex;
  align-items: stretch;
}

.main-sidebar {
  width: $sidebar-open-width;
  flex: 0 0 auto;
  background: $sidebar-background;
  transition: left 0.3s;
  font-family: $headings-font-family;
  display: flex;
  flex-direction: column;
}

.main-nav {
  margin-bottom: $spacer;

  li.nav-item.logo {
    height: 4.4rem;
    padding: $spacer * 0.5 $spacer * 0.5 $spacer * 0.5 ($sidebar-nav-link-left - ($spacer * 0.5));

    > a.nav-link {
      height: 100%;
      background: transparent url(../../images/maestro-wordmark.svg) no-repeat center center/80%;
      padding: 0;
      min-height: 0;
    }
  }

  > .nav-item {
    border-left: $sidebar-indicator-width solid transparent;
    transition: 0.3s border-left-color;

    .nav-icon {
      font-size: 1rem;
      width: 1.2rem;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      .nav-icon {
        font-size: 0.9rem;
      }
    }

    .has-children-icon {
      transition: 0.3s transform;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 2.4rem;
      border: none;
      background: none;
      width: 100%;
      text-align: left;
      position: relative;
      color: $gray-800;
      padding-left: $sidebar-nav-link-left;
      transition: 0.3s background-color;

      .nav-icon {
        color: $gray-600;
      }

      .nav-item-text {
        flex: 1;
        padding: 0 0 0 0.5rem;
        line-height: $line-height-sm;
        word-break: break-word;
      }

      &.active {
        background: $primary;
        color: $white;

        .nav-icon {
          color: $white !important;
        }
      }
    }

    &.subnav-open {
      border-left-color: $gray-600;
      background: $sidebar-submenu-background;

      .has-children-icon {
        transform: rotate(90deg);
      }

      > .nav-link {
        background: darken($sidebar-background, 11%);
      }
    }

    &.active-parent {
      border-left-color: $primary;
    }
  }
}

.main-sidebar-revealed {
  .nav-item.menu-toggle .nav-link {
    justify-content: flex-start;
  }
}

.main-column {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  // ensure page wrapper can never overflow so scrolling happens inside main column instead
  overflow: auto;

  > main {
    // main content must not grow or shrink to allow v-fill-height to accurately get the column height
    flex: 0 0 auto;
  }
}

.sidebar-lower {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
}

.sidebar-brandmark {
  position: sticky;
  bottom: $spacer;
  margin: auto 0 $spacer 0;
  background: transparent url(../../images/cornerstones-logo.svg) no-repeat center;
  background-size: contain;
  height: 3.125rem;
  width: $sidebar-open-width - ($spacer * 2);
}

.site-overlay {
  display: none;
}

@include media-breakpoint-down(sm) {
  // slide away mode

  .main-sidebar {
    width: $sidebar-mobile-width;
    z-index: 1010;
    position: fixed;
    height: 100%;
    overflow: auto;
    left: -$sidebar-mobile-width;
    will-change: transform;
    transition: transform 0.3s;
  }

  .main-sidebar-revealed {

    .main-sidebar {
      transform: translateX($sidebar-mobile-width);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    }

    .site-overlay {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 980;
      background: rgba(0, 0, 0, 0.2);
    }
  }
}

@include media-breakpoint-up(md) {
  .main-sidebar {

    .nav-item:not(.subnav-open):not(.active-parent):not(.logo):hover,
    .nav-item:not(.subnav-open):not(.active-parent):not(.logo):focus-within {
      border-color: darken($sidebar-background, 9%);
    }

    .nav-link:hover,
    .nav-link:focus {
      background: darken($sidebar-background, 9%);
      outline: none;
    }
  }

  //icon only mode
  .main-sidebar-collapsed {
    .main-sidebar {
      width: $sidebar-collapsed-width;
      text-align: center;

      .main-nav {
        li.nav-item.logo {
          > a.nav-link {
            background-color: transparent !important;
            background-image: url(../../images/cornerstones-brandmark.svg);
          }
        }
      }

      .main-nav {
        li.nav-item.gb-wls-logo {
          > a.nav-link {
            background-color: transparent !important;
            background-image: url(../../images/cornerstones-brandmark.svg);
          }
        }
      }

      .navbar-text {
        display: none;
      }

      .nav-item-text {
        display: none;
      }

      .has-children-icon {
        display: none;
      }

      .sidebar-brandmark {
        // is set back to display for specific products
        display: none;
        background-image: url(../../images/cornerstones-brandmark.svg);
        width: 100%;
        height: 2rem;
      }

      .nav-link:hover {
        .nav-item-text {
          display: block;
          position: absolute;
          left: 100%;
          top: 0;
          height: 100%;
          background: $primary;
          padding: 0.5rem 1rem;
          font-size: 0.8rem;
          color: #fff;
          z-index: 9999;
          white-space: nowrap;
          line-height: $line-height-base;
        }
      }
    }
  }
}

@each $key, $value in $customItemColours {
  .main-nav > .nav-item.#{$key}-item {
    border-left-color: map-deep-get($customItemColours, $key, normal) !important;

    .nav-icon {
      color: map-deep-get($customItemColours, $key, normal) !important;
    }

    // main header
    > .nav-link .nav-item-text {
      color: map-deep-get($customItemColours, $key, dark);
    }

    .nav-link {
      &:hover {
        background-color: map-deep-get($customItemColours, $key, light);
      }

      &.active,
      &:focus {
        background-color: map-deep-get($customItemColours, $key, normal);

        .nav-icon {
          color: $white !important;
        }

        .nav-item-text {
          color: $white !important;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .main-sidebar-collapsed .main-sidebar .nav-item.#{$key}-item {
      .nav-link:hover {
        .nav-item-text {
          background: map-deep-get($customItemColours, $key, normal);
          color: $white;
        }
      }
    }
  }
}
