.statutory-coverage-table {

  @media print {

    .li-row,
    .li-header,
    .li-cell,
    .li-header-cell {
      display: none;
    }

    .badge {
      print-color-adjust: exact;
    }
  }

  .pos-row {
    border-top-color: $cs-medium-grey;
    border-top-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: none;
  }

  .li-row {
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom-style: dotted;
    background: $cs-pale-grey;

    &:hover {
      background: $cs-light-grey;
    }
  }

  .li-header {
    border: none;
    background: $light;
  }

  .li-header-cell {
    border-top-style: none;
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: none;
    background: $light;
  }

  .li-cell {
    border-top-style: none;
    border-left-style: solid;
    border-right-style: solid;
    border-bottom-style: dotted;
    background: $cs-pale-grey;
    text-align: center;

    &:hover {
      background: $cs-light-grey;
    }
  }
}
