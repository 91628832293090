.lesson-teach {
  .nav-pills {
    .nav-link {
      border-bottom: 2px solid transparent;

      &.active {
        border-radius: 0;
        background-color: transparent;
        color: $primary !important;
        border-bottom-color: $primary;
      }
    }
  }

  .card-header {
    padding: 0;
  }

  .card-body {
    padding: 0;
  }
}
