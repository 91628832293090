.daterangepicker {
  font-family: inherit;
  border: $input-border-width solid $input-border-color;
  border-radius: $border-radius;

  .no-border & {
    border: none;
  }

  :before {
    border-bottom-color: $input-border-color !important;
  }

  td.in-range {
    background: rgba($primary, 0.15);
  }

  td.active {
    background: $primary;

    &:hover {
      background: darken($primary, 5%);
    }
  }

  select {
    @extend .custom-select;
    @extend .custom-select-sm;

    &.monthselect,
    &.yearselect,
    &.hourselect,
    &.minuteselect,
    &.secondselect {
      height: $input-height-sm;
      font-size: $input-font-size-sm;
      padding: $input-padding-y-sm $input-padding-x-sm;
      background: $input-bg;
      border: $input-border-width solid $input-border-color;
      line-height: $input-line-height-sm;
    }
  }
}

.cs-date-picker-input {

  &,
  &[readonly] {
    background: $input-bg;
  }

  &:disabled {
    background: $input-disabled-bg;
    pointer-events: none;
  }

  &.size-sm {
    height: 25px !important;
    font-size: 0.9em;
    padding: 0.2rem 0.4rem;
  }
}

.cs-datepicker-inline {
  position: relative;

  .daterangepicker {
    display: block !important;
    position: initial;
    top: initial !important;
    right: initial !important;
    bottom: initial !important;
    left: initial !important;
    margin-top: 0 !important;
    z-index: initial;
    overflow: hidden;
    width: auto;

    &:before,
    &:after {
      display: none;
    }

    .drp-calendar {
      width: 50%;
      max-width: 50%;
    }

    .calendar-table {

      td,
      th {
        width: auto;
      }
    }
  }
}

.cs-date-picker-container.disabled {
  pointer-events: none;

  .cs-datepicker-inline .daterangepicker {
    opacity: 0.8;
  }
}
