.project-overview-tiles {

  @import "shared";

  .overview-cell {
    min-width: 140px;
  }

  .overview-cell-td {
    padding: 1px;
  }

  .hover-content {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 100;
    border: 1px solid white !important;
  }

  .top-right-overlay-content {
    top: $spacer * 0.5;
    right: $spacer * 0.5;
    position: absolute;
    z-index: 1;

    .custom-checkbox {
      // remove label padding
      margin-right: -#{$spacer * 0.5};
    }
  }
}
