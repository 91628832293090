tr.b-table-has-details {
  border: $table-border-width solid $table-border-color;
  //border-top: none;
  border-bottom: none;
}

.b-table-details>td {
  background: transparent;
  border: $table-border-width solid $table-border-color;
  //border-top: none;
  padding: 1px;
  border-width: 1px;
}

.inline-table {
  margin-bottom: 0;

  thead {
    display: none;
  }

  tr {
    background: $white !important;
  }

  &.inline-transparent {
    tr {
      background: transparent !important;

      td {
        border-top-color: lightgray;
      }
    }
  }

  td {
    padding: $spacer * 0.125 $spacer * 0.25;
    border-top-width: 0;
    vertical-align: inherit;
  }

  label {
    margin: 0;
  }
}

.table-horz-border {
  tbody {
    td {
      border-top-width: 1px;
    }
  }
}
