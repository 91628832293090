$event-border-size: $spacer * 0.35;


.fc {
  --fc-event-text-color: #{$black};
  --fc-event-bg-color: #{$primary};
  --fc-event-border-color: #{$primary};
  --fc-now-indicator-color: #{$cs-develop};
  --fc-today-bg-color: #{rgba($primary, 0.1)};

  line-height: 1;

  .fc-timegrid-slot {
    height: 1.26rem;

    // Stepped rather than using percentage units to avoid rounding errors in overlap calculation
    @media screen and (height > 800px) {
      height: 1.3rem;
    }

    @media screen and (height > 850px) {
      height: 1.4rem;
    }

    @media screen and (height > 900px) {
      height: 1.5rem;
    }

    @media screen and (height > 1000px) {
      height: 1.6rem;
    }

    @media screen and (height > 1100px) {
      height: 1.7rem;
    }

    @media screen and (height > 1200px) {
      height: 1.8rem;
    }

    @media screen and (height > 1300px) {
      height: 1.9rem;
    }
  }

  .fc-scrollgrid {
    border: none;
  }

  .fc-scrollgrid-section-body td {
    border-bottom: none;
  }

  .fc-daygrid-event {
    border-radius: $border-radius-sm;
    cursor: pointer;

    @media print {
      border: none;
      border-radius: 0;
      border-bottom: $border-width solid $border-color;
    }
  }

  .fc-now-indicator-container {
    @media print {
      display: none;
    }
  }

  .fc-event {
    cursor: pointer;
    border: none;
    line-height: 1;


    .fc-event-main {
      background: rgba(255, 255, 255, 0.7);
      padding: #{$spacer * 0.25} #{$spacer * 0.25} #{$spacer * 0.25} $event-border-size;
      margin: 0 0 0 $event-border-size;
    }

    .fc-event-meta-container {
      font-size: var(--fc-small-font-size);
    }

    .event-taught {
      color: darken($success, 10%);
      filter: drop-shadow(0px 1px 0px white);
    }

    &.fc-h-event {

      .fc-event-main-frame {
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        align-items: baseline;
      }

      .fc-event-title-container {
        flex: 1 0 auto;
      }

      .fc-event-meta-container {
        margin-left: $spacer * 0.25;
      }
    }

    &.fc-v-event {
      overflow: hidden;

      .fc-event-main-frame {
        align-items: baseline;
        overflow: visible !important;
      }

      .fc-event-title-container {
        flex: 1 0 auto;
      }

      .fc-event-title {
        overflow: visible;
      }
    }
  }

  .fc-timegrid-event-short {
    .fc-event-main {
      padding: #{$spacer * 0.1} #{$spacer * 0.25} #{$spacer * 0.1} $event-border-size;
    }

    .fc-event-time {
      margin-left: $spacer * 0.25;

      &:after {
        content: none;
      }
    }

    .fc-event-meta-container {
      display: none;
    }
  }

}

.calendar-mode-user {
  .event-class-user-divider,
  .event-user-name {
    display: none;
  }
}

.dummy-event {
  opacity: 0.6;
}

@media print {
  .fc {

    table.fc-scrollgrid {
      width: 100% !important;

      tr {
        page-break-inside: auto;
      }
    }

    .fc-timegrid-bg-harness {
      position: initial !important;
    }

    .fc-col-header-cell-cushion,
    .fc-event {
      text-decoration: none;
    }
  }

  .event-print-indicator {
    print-color-adjust: exact;
    position: absolute;
    left: -$event-border-size;
    top: 0;
    height: 100%;
    width: $event-border-size;
  }

  .event-class-user-divider,
  .event-user-name {
    display: inline !important;
  }
}
