.assessment-output {

  .detailed-progress,
  .simple-progress {
    height: 2rem;
    print-color-adjust: exact;

    .bg-not-assessed {
      background: $gray-400;
      color: $black;
    }
  }

  .no-detailed {
    .detailed-progress {
      display: none;
    }
  }

  .has-detailed {
    .simple-progress {
      border-radius: $border-radius $border-radius 0 0;
    }

    .detailed-progress {
      border-radius: 0 0 $border-radius $border-radius;

      .bg-not-assessed span {
        display: none;
      }
    }
  }

  .assessment-chart {
    max-width: 600px;
    margin: 0 auto;
  }
}
