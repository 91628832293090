/**
 *  Printing fixes
 */

@media screen {
  .print-only {
    display: none !important;
  }
}

@media print {
  html,
  body,
  #app,
  .app-loading-wrapper,
  .app-container,
  .main-wrap {
    min-height: initial !important;
  }

  .page-break-inside-avoid {
    page-break-inside: avoid;
  }

  .page-break-before-always {
    page-break-before: always;
  }

  .page-break-after-always {
    page-break-after: always;
  }

  .main-wrap,
  .main-column {
    display: block;
  }

  .main-column {
    overflow: visible;
  }

  // Hide zoho widgets
  .zsiq_floatmain,
  .siq-msgbanr,
  .siq_rht,
  .zls-sptwndw {
    display: none !important;
  }

  // Remove the toolbars in Print view.
  .curriculum-stage-2 > .card-header,
  .nav.nav-tabs.card-header-tabs,
  .nav.nav-pills.card-header-pills {
    display: none !important;
  }

  .fill-height {
    overflow: visible !important;
    height: auto !important;
  }
}
