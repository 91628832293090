.curriculum-dashboard-table {
  table {
    th {
      border-top-style: none;
    }

    border-collapse: separate;
    border-spacing: 0;

    tr:hover {
      background-color: $cs-light-grey;
    }

    th:hover {
      background-color: $cs-light-grey !important;
    }
  }
}
