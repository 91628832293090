$project-carousel-width: 25% !default;
$project-carousel-max-width: 400px !default;

.project-show,
.activity-show {
  // prevent flash of page footer while loading
  min-height: $full-height-page;
}

.activity-stage-table {
  .info-col {
    width: 20%;
  }

  .knowledge-col {
    width: 40%;
  }

  .description-col {
    width: 40%;
  }

}

.project-carousel-col {
  position: relative;
  flex: 0 1 $project-carousel-width;
  max-width: $project-carousel-max-width;
}

.project-carousel {
  position: fixed;
  top: 0;
  height: 100vh;
  width: $project-carousel-width;
  z-index: -1;

  .carousel-inner,
  .carousel-item {
    height: 100%;
  }

  .carousel-item-content {
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    img {
      // used for preloading
      visibility: hidden;
    }
  }
}

.project-tab {
  color: $gray-900;

  .nav-link,
  .nav-link.active {
    color: inherit;
  }
}

.project-tab-memorable-experience,
.project-tab-engage {
  color: $cs-engage;
}

.project-tab-develop {
  color: $cs-develop;
}

.project-tab-develop-1 {
  color: $cs-develop;
}

.project-tab-develop-2 {
  color: $cs-develop;
}

.project-tab-develop-3 {
  color: $cs-develop;
}

.project-tab-develop-4 {
  color: $cs-develop;
}

.project-tab-innovate {
  color: $cs-innovate;
}

.project-tab-express {
  color: $cs-express;
}

// Love to investigate --------------------------------------
.project-tab-planning-and-preparation {
  color: $cs-engage;
}

.project-tab-let-s-investigate {
  color: $cs-develop;
}

.project-tab-summarising-learning {
  color: $cs-express;
}

//-----------------------------------------------------------
