@import "functions";

// Fonts
@import "fonts";
@import "colours";

// Variables
@import "variables";

// Mixins
@import "mixins";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";

// Libraries
@import "~daterangepicker/daterangepicker.css";

// Base styling
@import "base";

// Reusable Blocks
@import "blocks/animations";
@import "blocks/assessment";
@import "blocks/badges";
@import "blocks/buttons";
@import "blocks/cards";
@import "blocks/checkbox-button";
@import "blocks/cs-date-picker";
@import "blocks/cs-select";
@import "blocks/dropdown";
@import "blocks/form";
@import "blocks/heart";
@import "blocks/image-collection";
@import "blocks/layout";
@import "blocks/like-button";
@import "blocks/markdown-output";
@import "blocks/modal";
@import "blocks/project-preview";
@import "blocks/result-form";
@import "blocks/table-details";
@import "blocks/table-sorting";
@import "blocks/table-sticky-col";
@import "blocks/fill-height";
@import "blocks/table-vertical-header";
@import "blocks/tabs";
@import "blocks/text-highlight";
@import "blocks/toast";
@import "blocks/modification";
@import "blocks/source-multi-target-drag-drop-list";
@import "blocks/property-grid";
@import "blocks/indeterminate-progress";

// Custom styling
@import "blocks/codes";
@import "blocks/content";
@import "blocks/coverage";
@import "blocks/no-ui";
@import "blocks/pos";
@import "blocks/projects";
@import "blocks/sidebar";
@import "blocks/site-footer";
@import "blocks/site-header";
@import "blocks/zoho";

// Animations
@import "animations/shake-error";

// Transitions
@import "transitions/in-out-fade";

// Specific pages
@import "pages/assessment/output";
@import "pages/attainment/grouped-ability-list";
@import "pages/attainment/pupil-attainment-table";
@import "pages/attainment/subject-attainment-list";
@import "pages/attainment/subject-attainment-widget";
@import "blade/curriculum-design-output";
@import "pages/curriculum-statement/edit";
@import "pages/curriculum-statement/output";
@import "pages/curriculum/statutory-coverage-table";
@import "pages/entitlement-and-enrichment/edit";
@import "pages/events/calendar";
@import "pages/events/calendar-group";
@import "pages/long-term-plan/edit";
@import "pages/long-term-plan/output";
@import "pages/medium-term-plan/output";
@import "pages/progression/progression";
@import "pages/project/school-activity-designer";
@import "pages/project/show";
@import "pages/project/activity-show";
@import "pages/project/activity-output";
@import "pages/reports/school-curriculum-report";
@import "pages/curriculum-dashboard/project-overview-table";
@import "pages/curriculum-dashboard/curriculum-stage1";
@import "pages/curriculum-dashboard/curriculum-stage2";
@import "pages/curriculum-dashboard/project-subject-widget";
@import "pages/curriculum-dashboard/project-overview-tiles";
@import "pages/curriculum-dashboard/curriculum-year-group-preview";
@import "pages/curriculum-dashboard/curriculum-plan-output";
@import "pages/curriculum-dashboard/curriculum-dashboard-table";
@import "pages/curriculum-pro/curriculum-pro-skills";
@import "pages/curriculum-pro/curriculum-pro-narrative-terms";
@import "pages/lessons/lesson-table";
@import "pages/lessons/lesson-teach";
@import "pages/attainment-2020/learning-group-attainment-table";
@import "pages/pupil-reports/pupil-report-output";
@import "pages/subscription/subscribe";

//Themes
@import "themes/quick-teach";

// Utility styling
@import "utility/backgrounds";
@import "utility/cursors";
@import "utility/loading";
@import "utility/lists";
@import "utility/sizes";
@import "utility/tables";
@import "utility/text";
@import "print";
