.heart {
  svg {
    color: $like-btn-colour;
  }

  .fa-layers-text {
    color: white;
  }
}

.heart-unselected {
  svg {
    color: $cs-medium-grey;
  }

  .fa-layers-text {
    color: $cs-medium-grey;
  }
}
