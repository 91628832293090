.b-table.table {

  th:hover {
    &.b-table-sort-icon-left {
      background-color: $cs-light-grey !important;
    }
  }

  // Sort Styling
  > thead,
  > tfoot {
    > tr {
      > th {
        &[aria-sort] {
          // &.sorting
          padding-left: 1.125em;
          padding-right: $table-cell-padding;

          // Up/Down sort=null indicator
          &::after {
            display: none;
            left: 0.35em;
            right: auto;
          }

          &[aria-sort="ascending"]::after,
          &[aria-sort="descending"]::after {
            padding-right: 1.125em;
          }

          // Ascending indicator
          &[aria-sort="ascending"]::after {
            // &.sorting_asc::after.sorting_asc
            display: block;
          }

          // Descending indicator
          &[aria-sort="descending"]::after {
            // &.sorting_desc::after
            display: block;
          }
        }
      }
    }
  }
}

.b-table.table.table-sm {

  > thead,
  > tfoot {
    > tr {
      > th[aria-sort] {
        // &.sorting
        padding-right: $table-cell-padding-sm;
        background-position-y: calc(100% - 0.45rem) !important;
      }
    }
  }
}
