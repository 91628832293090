.curriculum-statement-edit {
  .school-logo {
    max-width: 150px;
    max-height: 150px;
  }

  .big-ideas {
    @include media-breakpoint-up(sm) {
      column-count: 2;
      column-gap: $spacer;
      orphans: 1;
      widows: 1;

      .card {
        display: inline-block; // Don't let them vertically span multiple columns
        width: 100%; // Don't let their width change
      }
    }
  }

  .curriculum-statement-section {
    .autosize-textarea {
      min-height: 0;
    }

    .drop-area {
      min-height: 200px;
      outline: $input-border-width dashed $input-border-color;
      outline-offset: $spacer * .25;
      background: rgba(255, 255, 255, 0.6);
    }
  }
}
