.subject-attainment-list {
  .highlight-hover-outer {
    li:hover {
      background: rgba($cs-light-grey, .5);
    }
  }

  .highlight-hover-inner {
    li:hover {
      background: $cs-light-grey;
    }
  }

  .fixed-width {
    width: 40px;
    min-width: 40px;
  }

  .lesson-header {
    margin-left: 62px;
  }

  .fixed-widget {
    width: 20px;
    min-width: 20px;
  }
}
