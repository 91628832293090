.indeterminate-progress {

  position: relative;
  width: auto;
  height: 20px;
  border-radius: 2px;
  border: 2px solid #ededed;
  overflow: hidden;

  .loader {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 2px;
    background: $cs-covered;
    left: 0%;
  }

  .loader {
    left: -100%;
    animation: load 2s linear infinite;
  }

  @keyframes load {
    0% {
      left: -100%;
    }

    100% {
      left: 100%;
    }
  }

}
