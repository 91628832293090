@media screen {
  .sticky-col-table-container {
    position: relative;

    table.table {
      margin: 0;
      border-collapse: separate;
      border-spacing: 0;

      .sticky-col {
        position: sticky !important;
        left: 0;
        background: $light;
        z-index: 99;
      }

      th.sticky-col {
        background-color: $white;
        z-index: 200;
        top: 0;
      }

      .sticky-header {
        position: sticky !important;
        top: 0;
        z-index: 100;
        background: $white;
      }

      .b-table-details>td {
        //background: $gray-200;

        &>* {
          display: inline-block;
          position: sticky;
          left: 0;
        }
      }

    }
  }
}
