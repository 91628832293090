.curriculum-code-text {
  color: $cs-develop;
  font-size: 9pt;

  &:before {
    content: "["
  }

  &:after {
    content: "]"
  }

  white-space: nowrap !important;
}
