$cs-engage: #F79C32;
$cs-develop: #AF4C75;
$cs-innovate: #538EAA;
$cs-express: #D0D767;

$black: #2A2A2B;
$cs-dark-grey: #5a5a5a;
$cs-medium-grey: #828282;
$cs-medium-light-grey: #c0c0c0;
$cs-light-grey: #e6e6e6;
$cs-pale-grey: #f6f6f6;
$cs-covered: #28a745;
$cs-light-covered: #7ddb93;
$cs-pale-covered: #a9e8b8;
$cs-not-covered: #dc3545;

$cs-green-background: #8FCE9D;
$cs-amber-background: #E6B783;
$cs-red-background: #E58F91;

$cs-pale-green-background: #daedd6;
$cs-pale-amber-background: #f9e8d6;
$cs-pale-red-background: #f6e4e4;

$cs-year-nursery: #FFC400;
$cs-year-reception: #FFC400;
$cs-year-1: #0BD46F;
$cs-year-2: #155FB6;
$cs-year-3: #E16FE1;
$cs-year-4: #FF9C55;
$cs-year-5: #55AAAA;
$cs-year-6: #FF5555;

$cs-curriculum-pro: #0c226b;

$primary: $cs-innovate;
$info: #98BBCC;

$theme-colors: (
  "engage": $cs-engage,
  "memorable-experience": $cs-engage,
  "develop": $cs-develop,
  "innovate": $cs-innovate,
  "express": $cs-express,
  "partial-covered": #BF7C48,
  "pale-warning": #fadfc1,
  "pale-danger": #D67BB0,
  "light-covered": $cs-light-covered,
  "pale-covered": $cs-pale-covered,
  "concept": $cs-medium-grey,
  "aspect": $cs-develop,
  "subject": $cs-innovate,
  "curriculum-pro": $cs-curriculum-pro,
  // RAG
  "rag-green": $cs-green-background,
  "rag-amber": $cs-amber-background,
  "rag-red": $cs-red-background,
  "pale-rag-green": $cs-pale-green-background,
  "pale-rag-amber": $cs-pale-amber-background,
  "pale-rag-red": $cs-pale-red-background,
  "control": $cs-medium-light-grey,
);

$activityStages: (
  "memorable-experience": #7D813E,
  "engage": $cs-engage,
  "develop": $cs-develop,
  "innovate": $cs-innovate,
  "express": $cs-express,
);

$bigIdeas: (
  "change": #703172,
  "comparison": #ffbeea,
  "creativity": #fad600,
  "humankind": #f88914,
  "investigation": #e86b6d,
  "materials": #1e4a83,
  "nature": #348914,
  "place": #d4292c,
  "place-and-space": #d4292c,
  "processes": #53b89a,
  "significance": #009fe3,
);

$yearGroups: (
  "yg-nursery": $cs-year-nursery,
  "yg-reception": $cs-year-reception,
  "yg-1": $cs-year-1,
  "yg-2": $cs-year-2,
  "yg-3": $cs-year-3,
  "yg-4": $cs-year-4,
  "yg-5": $cs-year-5,
  "yg-6": $cs-year-6,
  "ks-eyfs": #36A8C3,
  "ks-1": #B51E82,
  "ks-2-lower": #007336,
  "ks-2-upper": #3F3A69,
);

$assessments: (
  "assessment-above": #304D78,
  "assessment-greater-depth": #116428,
  "assessment-within": #4DAB57,
  "assessment-just-within": #78C384,
  "assessment-borderline": #BF7C48,
  "assessment-below": #A83432,
);

$paleAssessments: (
  "pale-assessment-above": #dbe4f1,
  "pale-assessment-greater-depth": #bbe2c1,
  "pale-assessment-within": #dcf0df,
  "pale-assessment-just-within": #ecf7ee,
  "pale-assessment-borderline": #ebd5c5,
  "pale-assessment-below": #f2d9d8,
);

$pos: (
  "pos-coverage": $cs-innovate,
  "pos-breadth": $cs-develop,
  "pos-discrete": $cs-develop,
);

$curriculum: (
  "curriculum-pos": $cs-innovate,
  "curriculum-li": $cs-engage,
  "curriculum-cf-skill": $cs-develop,
  "curriculum-knowledge": $cs-innovate,
);

$wales: (
  "wales-ambitious": #bad157,
  "wales-enterprising": #3aa5aa,
  "wales-ethical": #715195,
  "wales-healthy": #c72d4b,
);

$qtPrimary: #f1854f;
$qtPrimaryDark: #d6503d;
$qtBackground: #38484e;
$qtContentBackground: #2b383d;
$qtYellow: #fed141;

$yiq-contrasted-threshold: 190;

$sizes: (
  20: 20%,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);

// Coverage banding
$coverage-none: transparent;
$coverage-low: lighten($cs-covered, 45%);
$coverage-medium-low: lighten($cs-covered, 30%);
$coverage-medium-high: lighten($cs-covered, 15%);
$coverage-high: $cs-covered;

// Body
$body-bg: #fff;
$body-color: #464647;

// Components
$caret-width: .3em;
$caret-vertical-align: $caret-width * 0.5;

// Typography
$headings-font-family: "Lato", Verdana, Geneva, sans-serif;
$font-family-sans-serif: "Karla Variable", Verdana, Geneva, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem;
$line-height-sm: 1.2;
$text-color: $black;

$border-radius: .5rem;
$border-radius-lg: 1rem;
$border-radius-sm: .375rem;

// Buttons
$buttons-font-family: $headings-font-family;

// Inputs
$input-font-family: $font-family-base;
$input-border-color: lighten($text-color, 40%);
$input-placeholder-color: lighten($text-color, 30%);

// Breadcrumbs
$breadcrumb-padding-y: 0.5rem;

// Toasts
// $b-toast-bg-level: 0.3;
$b-toast-background-opacity: 0.95;

// Printing
// we'll handle print ourselves
$enable-print-styles: false;
$print-page-size: a4;

// Utilities
$zindex-sticky: 100;
$zindex-site-header: 500;
$zindex-help-panel: 510;

// Custom
$footer-height: 68px;
$like-btn-colour: #dc3545;
$sticky-bg: rgba(255, 255, 255, 0.9);
$highlight-bg-color: rgba($cs-express, 0.5);
// 113px = header height
$full-height-page: calc(100vh - (#{$footer-height} + 113px));
