.school-curriculum-report {
  //table {
  //    width: auto;
  //}

  th {
    padding: 4px 2px;
  }

  .th-align-right {
    text-align: end;
    padding-right: 2px;
  }

  td {
    white-space: nowrap;
    padding: 2px;
  }

  td:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wonde-logo {
    height: 1.5em;
    width: auto;
  }
}
