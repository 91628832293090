table.table.b-table {

  &>thead>tr>th.vertical-header,
  &>tfoot>tr>th.vertical-header {
    padding-top: $table-cell-padding * 2;

    &>div {
      display: inline-block;
      overflow: hidden;

      width: 1.5em;
      line-height: 1.5;

      &>span {
        display: inline-block;
        white-space: nowrap;

        transform: translate(0, 100%) rotate(-90deg);
        transform-origin: 0 0;

        //truncation
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:before {
          content: "";
          float: left;
          margin-top: 100%;
        }
      }
    }

    &.sorting {
      padding-left: $table-cell-padding;
      padding-bottom: 1.75rem;

      &:before,
      &:after {
        padding-bottom: 0.25rem;
        left: 0;
        right: 0;
      }
    }
  }

  &.table-sm>thead>tr>th.vertical-header,
  &.table-sm>tfoot>tr>th.vertical-header,
  &.table-sm>thead>tr>th.vertical-header.sorting,
  &.table-sm>tfoot>tr>th.vertical-header.sorting {
    padding-top: $table-cell-padding-sm * 2;
    padding-left: $table-cell-padding-sm;
  }

  &>thead>tr>th.vertical-header-2-lines {
    &>div {
      width: 3em;
    }
  }
}
