&.year-group-tile {
  border: 1px solid $cs-medium-light-grey;
  width: 230px;
  height: 100%;
  padding: $spacer * 0.5;
  border-radius: $border-radius;
}

.year-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: $cs-medium-grey;
}

.number-container {
  line-height: 1;

  .number-text {
    font-size: 1.3rem;
    font-weight: bold;

    &.project-count {
      font-size: 2.7rem;
    }
  }

  .number-description-text {
    display: block;
  }
}
