.cs {
  &-select {
    position: relative;
    min-width: 8rem;

    &.is-loading {
      cursor: progress;
    }

    .cs-select-form-control {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      height: auto;
      min-height: $input-height;

      &.form-control-sm {
        min-height: $input-height-sm;
      }

      &.form-control-lg {
        min-height: $input-height-lg;
      }
    }

    .input-group-sm > & .cs-select-form-control {
      min-height: $input-height-sm;
      padding: $input-padding-y-sm $input-padding-x-sm;
      @include font-size($input-font-size-sm);
      line-height: $input-line-height-sm;
      @include border-radius($input-border-radius-sm);
    }

    .input-group-lg > & .cs-select-form-control {
      min-height: $input-height-lg;
      padding: $input-padding-y-lg $input-padding-x-lg;
      @include font-size($input-font-size-lg);
      line-height: $input-line-height-lg;
      @include border-radius($input-border-radius-lg);
    }

    .input-group > &:not(:first-child) .cs-select-form-control {
      @include border-left-radius(0);
    }

    .input-group > &:not(:last-child) .cs-select-form-control {
      @include border-right-radius(0);
    }

    .cs-select-placeholder {
      color: $input-placeholder-color;
    }

    .cs-select-value,
    .cs-select-placeholder {
      flex: 1 1 auto;
      cursor: default;
    }

    .cs-select-value-item {
      font-size: 85%;
      margin: 0.5px #{$spacer * 0.25} 0.5px 0;
    }

    .all-selected-text {
      margin-right: $spacer * 0.25;
    }

    .cs-select-remove-item-button {
      @extend .close;
      float: none;
      margin-left: $spacer * .25;
      font-size: inherit;
      color: $white;
      text-shadow: 0 1px 0 $black;

      &:hover {
        color: $white;
      }
    }

    .cs-select-remove-item-button-outline {
      @extend .close;
      float: none;
      margin-left: $spacer * .25;
      font-size: inherit;
      color: $cs-medium-grey;
      text-shadow: 0 1px 0 $black;

      &:hover {
        color: $cs-medium-grey;
      }
    }

    .cs-select-clear {
      @extend .close;
      margin-left: auto;
      float: none;
      font-size: 1rem;
    }

    .cs-select-dropdown-caret {
      @include caret;
      margin-left: $spacer * 0.25;
    }

    &.is-open {
      .cs-select-form-control {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: $input-focus-color;
        background-color: $input-focus-bg;
        border-color: $input-focus-border-color;
        outline: 0;

        @if $enable-shadows {
          box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
          box-shadow: $input-focus-box-shadow;
        }
      }

      .cs-select-dropdown-caret {
        @include caret(up);
      }
    }

    &.cs-select-sm {

      .cs-select-option,
      .cs-select-loading {
        padding: #{$input-padding-y-sm * 2} $input-padding-x-sm #{$input-padding-y-sm * 2} $input-padding-x-sm;
        font-size: $input-font-size-sm;
      }

      .cs-select-loading {
        top: $input-height-sm;
      }
    }

    &.cs-select-lg {

      .cs-select-option,
      .cs-select-loading {
        padding: #{$input-padding-y-lg * 2} $input-padding-x-lg #{$input-padding-y-lg * 2} $input-padding-x-lg;
        font-size: $input-font-size-lg;
      }

      .cs-select-loading {
        top: $input-height-lg;
      }
    }
  }

  &-search {
    &-clear {
      font-size: 1.2em;
      padding: 0 15px;

      &:hover {
        text-decoration: none;
        font-weight: bolder;
      }
    }
  }
}

.cs-select-dropdown-container {
  z-index: $zindex-popover;

  .cs-select & {
    // Self contained mode
    position: absolute;
    top: calc(100% - #{$input-border-width});
    left: 0;
    right: 0;
  }
}

.cs-select-dropdown {
  overflow: hidden;
  box-shadow: $box-shadow;
  background: $white;
  border: $border-width solid $border-color;
  border-radius: 0 0 $border-radius $border-radius;
  min-height: 7rem;

  .cs-select-options {
    max-height: 300px;
    overflow: auto;

    &-action {
      position: absolute;
      right: 0;
      padding-right: $breadcrumb-padding-y;
    }
  }

  .cs-select-option,
  .cs-select-loading {
    padding: #{$input-padding-y * 2} $input-padding-x #{$input-padding-y * 2} #{$input-padding-x * 0.5};
    word-break: break-word;
    line-height: $line-height-sm;
  }

  .cs-select-option {
    cursor: pointer;
  }

  .cs-select-divider {
    background: $cs-light-grey;
    cursor: default;

    &.sticky-divider {
      position: sticky;
      top: 0;
      z-index: 1000;
    }
  }

  .cs-select-loading {
    background: $gray-300;
    position: absolute;
    top: $input-height;
    z-index: 10;
    width: 100%;
    opacity: 0.8;
  }

}

.cs-select-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
