.no-ui {

  .site-header,
  .main-sidebar {
    display: none !important;
  }

  .main-column {
    margin-left: 0 !important;
  }

  .site-footer {
    left: 0 !important;
  }

  .main-content.full-height {
    min-height: 100vh;
    height: auto;

    @include media-breakpoint-up(md) {
      min-height: calc(100vh - #{$footer-height});
    }
  }
}
