.markdown-output-container {
  p {
    word-break: break-word;
  }

  table {
    @extend .table;
  }

  &.markdown-output-inline {
    display: inline;

    .markdown-output,
    .markdown-output>p:first-child {
      display: inline;
    }

    .markdown-output {
      >p:first-child {
        display: inline;
        margin-bottom: 0;
      }

      >p:nth-child(2) {
        margin-top: $spacer;
      }

      >p:last-child {
        margin-bottom: 0;
      }
    }

  }

}
