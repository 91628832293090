$footer-height: 60px !default;

.site-footer {
  margin-top: auto;
  font-size: 0.8rem;
  padding: #{$spacer * 0.75} $spacer;
  background: $light;
  border-top: $border-width solid $border-color;
  color: $body-color;

  @media print {
    background: transparent !important;
  }

  .nav-link {
    padding: ($spacer * 0.5) ($spacer * 0.75);
  }
}
