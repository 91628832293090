.curriculum-pro-skills {
  .pos-container {
    list-style: none;
    padding: 0;

    &.fixed-height {
      // its important to use 'max-height' attribute as its used to determine if the target element has a scrollbar
      max-height: 150px;
      overflow-y: auto;
    }
  }

  .markdown-output {
    p {
      margin: 0;
    }
  }
}
