.property-grid {
  .outer-container {
    &.limit-height {
      max-height: 300px;
      overflow-y: auto;
    }

    border: 2px solid $cs-medium-grey;
  }

  tr {
    td:first-child {
      padding-right: 2rem;
      background: $cs-pale-grey;
    }

    td:nth-child(2) {
      text-align: right;
    }
  }

  td {
    padding: 1px 0.5rem;
    margin: 1px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }
}
