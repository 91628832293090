$project-preview-main-height: 400px;

.project-preview {
  width: 100%;
  height: $project-preview-main-height;
  transition: opacity 0.3s linear;
  display: flex;
  align-items: stretch;
  background: $white;
  position: relative;

  .project-preview-content {
    padding: $spacer;
    flex: 1 0 55%;
    overflow: auto;
    z-index: 1;
  }

  .project-preview-image {
    flex: 0 1 45%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .project-preview-select {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 2;
  }

  .project-preview-view {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 2;
  }
}
