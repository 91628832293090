.curriculum-stage-2 {

  #planning,
  #progression,
  #projects-tabs,
  #coverage-tabs {
    .card-header {
      background-color: transparent;
    }

    .custom-tabs {
      .nav-link {
        border-bottom: 2px solid transparent;

        &.active {
          border-radius: 0;
          background-color: transparent;
          color: $primary !important;
          border-bottom-color: $primary;
        }
      }
    }
  }
}
