.like-button {
  .btn {
    border-color: $like-btn-colour;
    border-style: none;
  }

  .like-icon {
    color: gray;
    display: inline-block;
  }

  &.checked {
    .like-icon {
      color: $like-btn-colour;
    }
  }

  .dropdown-menu {
    z-index: 100;
  }

  .pulse {
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    box-shadow: 0 0 0 rgba($like-btn-colour, 0.2);
    animation: like-pulse 2s 1;
  }

  .pulse-forever {
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    box-shadow: 0 0 0 rgba($like-btn-colour, 0.2);
    animation: like-pulse 2s infinite;
  }

  &.readonly {
    opacity: $btn-disabled-opacity;
  }

  &:not(.readonly):hover {
    .like-icon {
      color: $like-btn-colour;
    }

    .pulse {
      animation: like-pulse 2s infinite;
    }
  }
}
