.pupil-attainment-table {
  .selected-cell {
    background: $cs-pale-grey !important;
    border-radius: 5px 5px 0px 0px;
  }

  .selected-row {
    background: $cs-pale-grey;
  }

  .core-boundary {
    border-left: 2px solid dimgrey !important;
  }

  table {
    th {
      border-top-style: none;
    }
  }
}
