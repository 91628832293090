.grouped-ability-list {
  .highlight-hover {
    li:hover {
      background: $cs-light-grey;
    }
  }

  .fixed-widget {
    width: 20px;
    min-width: 20px;
  }

  .attainment-widget-in-list {
    transform: translate(-2px, 5px);
  }
}
