body.pdf {
  page: pdf;
  font-size: 10pt;

  &.landscape {
    page: landscape;
  }
}

.generated-at {
  string-set: generatedat content();
  display: none;
}

.curriculum-design-output {
  @media print {
    max-width: 100%;
  }

  &.mode-preview {
    @media screen {
      padding: $spacer;
      margin: 70px auto 0;
      max-width: 1000px;
    }
  }

  &.mode-output {
    @media screen {
      margin-top: $spacer;
    }
  }

  &.mode-pdf {
    max-width: 100%;

    .card-body {
      padding: $spacer * 0.75;
    }
  }

  .school-logo {
    max-height: 150px;
    max-width: 250px;
  }

  .table {
    line-height: 1.2;
    word-break: break-word;
  }

  .badge {
    white-space: normal;
  }

  .big-ideas {
    column-count: 2;
    column-gap: $spacer;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }

    .card-body {
      padding: $spacer * 0.75;
    }

    @media screen {
      @include media-breakpoint-down(sm) {
        column-count: 1;
      }
    }
  }
}
