.curriculum-design-output.curriculum-statement-output {
  .entitlement {
    page: landscape;
  }

  .big-ideas {
    @include media-breakpoint-up(sm) {
      column-count: 2;
      column-gap: $spacer;
      orphans: 1;
      widows: 1;

      .card {
        display: inline-block; // Don't let them vertically span multiple columns
        width: 100%; // Don't let their width change
      }
    }
  }

  .ee-table {
    width: 100%;
    font-size: 0.7rem;

    th,
    td {
      padding: $spacer * 0.5;
    }
  }
}
