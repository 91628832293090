.b-toaster {

  @media print {
    display: none;
  }

  &.b-toaster-top-right {
    @include media-breakpoint-up(md) {
      right: $spacer;
      top: 5rem;
    }
  }
}
