$site-header-padding-y: $spacer * 0.75 !default;
$site-header-padding-x: $spacer !default;

.site-header {
  position: relative;
  z-index: $zindex-site-header;

  .header-main {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: flex-end;
    justify-content: space-between;
    padding: $site-header-padding-y $site-header-padding-x 0;
  }

  @media print {
    position: static !important;

    .header-main {
      background: transparent !important;

      &:has(.page-title:empty) {
        display: none;
      }
    }
  }

  .page-title {
    margin: 0 $site-header-padding-x $site-header-padding-y 0;
    flex: 1 0 auto;
    max-width: 100%;
    text-wrap: pretty;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      margin-bottom: 0;
      line-height: 1;
    }
  }

  .auth-session {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: auto;
    flex: 1 1 auto;
    justify-content: end;

    .logo {
      width: 6rem;
      margin-right: auto;
      align-self: stretch;
      background: transparent url(../../images/maestro-wordmark.svg) no-repeat left center;
      background-size: contain;

      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    > * {
      margin-bottom: $site-header-padding-y;
    }

    .search-input {
      flex: 1 1 auto;
      min-width: 18.75rem;
      max-width: 30rem;

      @include media-breakpoint-down(xs) {
        width: 100%;
        order: 9999;
        margin-right: 0 !important;
      }
    }
  }
}

.header-main {
  background: $gray-100;
  border-bottom: 1px solid $gray-300;

  .curriculum-dashboard-title {
    &-header {
      flex-grow: 1;
    }

    &-warning {
      flex-shrink: 0;
    }
  }
}

.page-actions {
  background: $sticky-bg;
  backdrop-filter: blur(3px);
  border-bottom: 1px solid $gray-300;
  padding: ($spacer * 0.75) $spacer 0;

  .page-actions-sub,
  .vue-portal-target {
    display: contents;
  }

  &,
  .page-actions-group-container,
  .page-actions-group {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  .page-actions-group-container {
    flex-wrap: nowrap;
    width: 100%;
  }

  &,
  .page-actions-group-container,
  .page-actions-group,
  .page-actions-sub,
  .vue-portal-target {
    &:empty {
      display: none !important;
    }

    > * {
      margin-bottom: $spacer * 0.75;
    }

    & > .input-group,
    & > .form-control,
    & > .cs-select {
      flex: 1 1 auto;

      @include media-breakpoint-up(md) {
        flex: 0 1 auto;
      }
    }

    .page-actions-group-container,
    .page-actions-group {
      margin-bottom: 0;
    }

    .page-actions-line-break {
      flex: 1 0 100%;
      margin: 0 !important;
    }
  }
}
