.checkbox-button {
  .checkbox-icon {
    opacity: 0.1;
    color: $white;
  }

  .checked {
    .checkbox-icon {
      opacity: 1;
    }
  }

  &:not(.readonly):hover {
    .checkbox-icon {
      opacity: 0.5;
    }
  }

  &.readonly {
    opacity: $btn-disabled-opacity;
  }
}
