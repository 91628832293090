.project-overview-table {

  @import "shared";

  .overview-cell {
    min-width: 140px;
  }

  .overview-cell-td {
    padding: 1px;
  }

  .hover-content {
    top: 0;
    right: 0;
    position: absolute;
    z-index: 100;
    border: 1px solid white !important;
  }

  .icon-checkbox {
    font-size: 14pt;
  }

  .icon-checkbox-small {
    font-size: 12pt;
  }
}
