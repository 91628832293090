@mixin badge-outline-variant($colour) {
  color: $colour;
  border: 1px solid $colour;
}

.badge {
  @media print {
    print-color-adjust: exact;
  }
}

.badge-sm {
  padding: #{$spacer * 0.125} #{$spacer * 0.2};
}

// simulate common notification badge - float a few pixels above baseline
.notification-badge {
  transform: translate(0, -10px);
  border-radius: 50% !important;
}

@each $name, $colour in $theme-colors {
  .badge-outline-#{$name} {
    @include badge-outline-variant($colour);
  }
}

@each $name, $colour in $bigIdeas {
  .badge-big-idea-#{$name} {
    @include badge-variant($colour);
  }
}

@each $name, $colour in $yearGroups {
  .badge-#{$name} {
    @include badge-variant($colour);
  }

  .badge-outline-#{$name} {
    @include badge-outline-variant($colour);
  }
}

@each $name, $colour in $assessments {
  .badge-#{$name} {
    @include badge-variant($colour);
  }

  .badge-outline-#{$name} {
    @include badge-outline-variant($colour);
  }
}

@each $name, $colour in $pos {
  .badge-#{$name} {
    @include badge-variant($colour);
  }

  .badge-outline-#{$name} {
    @include badge-outline-variant($colour);
  }
}

@each $name, $colour in $wales {
  .badge-#{$name} {
    @include badge-variant($colour);
  }
}

@each $name, $colour in $curriculum {
  .badge-#{$name} {
    @include badge-variant($colour);
  }

  .badge-outline-#{$name} {
    @include badge-outline-variant($colour);
  }
}
