.curriculum-stage-1 {
  .input-group-text {
    width: 150px;
  }

  .input-group-button {
    width: 150px;
  }

  .input-group-prepend {
    width: 150px;

    .btn {
      width: 150px;
      text-align: left;
    }
  }

  .b-dropdown {
    width: 150px;

    .btn {
      color: #505050;
      text-align: left;
      border-color: #C0C0C0;
      background-color: #E9EBED;
    }

    .btn:hover {
      background-color: #484848;
      color: white;
    }
  }

  .input-group-append {
    width: 140px;

    .input-group-text {
      padding: 4px;
    }

    .custom-select {
      border-radius: 0px 4px 4px 0px;
    }
  }
}
