.list-inside {
  list-style-position: inside;
  padding-left: 0;
}

.list-checks {

  ::marker {
    content: "\2714";
  }

  li {
    padding-left: 0.5em;
  }
}
