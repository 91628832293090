.route-register,
.subscription-modal {

  .card.cs-stepper-content {
    background: $qtContentBackground;
    border: none;
    color: $white;

    > .card-header,
    > .card-footer {
      background: $qtPrimary;
      border: none;
    }

    > .card-footer {
      display: flex;
      justify-content: center;
    }
  }

  a, .btn-link {
    color: $qtPrimary;

    @include hover() {
      color: $qtPrimaryDark;
    }
  }

  [class*="btn-outline"]:not(:hover) {
    color: $white;
  }

  .invalid-feedback {
    color: $white;
  }
}

.route-register {
  .main-content {
    background: $qtBackground;
    color: $white;
  }

  .content {
    max-width: 960px;
  }

  .card {
    color: $body-color;
  }
}

.subscription-modal {
  color: $white;

  .close {
    color: $white;
  }

  .modal-header,
  .modal-footer {
    background: $qtPrimary;
    border: none;
  }

  .modal-body {
    background: $qtBackground;
  }
}
