.image-collection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 200px;
  margin: 0 -#{$spacer * 2} $spacer;

  > img {
    max-width: 180px;
    max-height: 180px;
    height: auto;
    border-radius: $border-radius;
    margin-left: -30%;
    border: $card-border-width solid $card-border-color;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

    &.fallback {
      aspect-ratio: 0.71;
      object-fit: cover;
    }

    &:first-child {
      margin-left: 0;
    }

    &:nth-child(odd) {
      margin-top: 10px;
    }

    &:nth-child(even) {
      margin-top: -10px;
    }
  }
}
