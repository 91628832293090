.source-multi-target-drag-drop-list {
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  ul.list-group {
    min-height: 100%;
    margin: 0;
  }

  .target-list-container {

    &.col-3,
    &.col-2 {
      padding: 1px;
    }

    .scroll-container {
      height: 500px;
      overflow-y: scroll;
    }

    .small-scroll-container {
      height: 250px;
      overflow-y: scroll;
    }
  }

  .source-container {
    .scroll-container {
      height: 500px;
      overflow-y: scroll;
    }
  }

  .list-group-item {
    // must add padding to child instead
    padding: 0;

    &.source {
      cursor: move;
    }

    &.target {
      cursor: move;

      &:hover {
        background-color: $cs-light-grey;
      }
    }
  }
}
