.long-term-plan-edit {
  .ltp-dropzone {
    width: 100%;
    min-height: 76px;
  }

  .tab-content {
    height: calc(100% - 39px);
  }

  .cycle-project {
    width: 100px;
    position: relative;
    cursor: move;
    margin: #{$spacer * 0.25};

    .inner-content {
      .badge {
        box-shadow: 0 0 20px rgba(0, 0, 0, 1);
      }
    }
  }

  .sortable-drag,
  .ltp-col-target .sortable-chosen {
    .used-in {
      display: none;
    }
  }
}
