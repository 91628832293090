.calendar-colour-picker-wrapper {
  position: relative;

  svg {
    position: absolute;
    top: 0.15rem;
    left: 0.15rem;
    z-index: 5;
    pointer-events: none;
  }

  input[type="color"] {
    display: block;
    padding: 0;
    width: 1.2rem;
    height: 1.2rem;
    border: none;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }

    &::-webkit-color-swatch {
      border: none;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: $border-radius-sm;
    }
  }
}
