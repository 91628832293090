.learning-group-attainment-table {

  td {
    &.attainment-cell {
      &.attainment-at {
        background-color: $cs-pale-green-background !important;
      }

      &.attainment-pa {
        background-color: $cs-pale-amber-background !important;
      }

      &.attainment-na {
        background-color: $cs-pale-red-background !important;
      }
    }
  }

  td {
    &.objective-col-xl {
      min-width: 40rem;
      width: 40rem;
    }

    &.objective-col-lg {
      min-width: 30rem;
      width: 30rem;
    }
  }

  th {
    padding: 4px;

    &.table-curriculum-li {
      min-width: 17rem;
      width: 17rem;
    }

    &.table-curriculum-knowledge {
      min-width: 17rem;
      width: 17rem;
    }
  }

  .table-b-table-default {
    &.table-curriculum-li {
      &.main-objective {
        // background-color: #f4d9bc !important;
        border-left: 1px solid $cs-light-grey;
      }

      &.ep-objective {
        border-left: 1px solid $cs-light-grey;
        background: $cs-pale-grey;
        //background-color: #FFF0E0 !important;
        // background: #FFF0E0 repeating-linear-gradient(-45deg,
        //         white,
        //         white 10px,
        //         #FFF0E0 10px,
        //         #FFF0E0 20px);
      }

      &.optional-objective {
        border-left: 1px solid $cs-light-grey;
        background: $cs-pale-grey;
        //background-color: #FFF0E0 !important;
        // background: #FFF0E0 repeating-linear-gradient(-45deg,
        //         white,
        //         white 10px,
        //         #FFF0E0 10px,
        //         #FFF0E0 20px);
      }
    }
  }

  .table-b-table-default {
    &.table-curriculum-knowledge {
      &.main-objective {
        // background-color: #b8e1e7 !important;
        border-left: 1px solid $cs-light-grey;
      }

      &.ep-objective {
        //background-color: #E3F6F9 !important;
        border-left: 1px solid $cs-light-grey;
        background: $cs-pale-grey;
        // background: #E3F6F9 repeating-linear-gradient(-45deg,
        //         white,
        //         white 10px,
        //         #E3F6F9 10px,
        //         #E3F6F9 20px);
      }

      &.optional-objective {
        border-left: 1px solid $cs-light-grey;
        background: $cs-pale-grey;
        //background-color: #E3F6F9 !important;
        // background: #E3F6F9 repeating-linear-gradient(-45deg,
        //         white,
        //         white 10px,
        //         #E3F6F9 10px,
        //         #E3F6F9 20px);
      }
    }
  }

  .objective-column {
    min-width: 50rem;
  }

  thead {
    tr {
      th {

        &.description-row {
          // this is important - without an explicit height content inside a <th> cell won't respect height: 100%
          height: 6rem;

          div {
            &.description-div {
              height: 100%;
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

}
