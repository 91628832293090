.animate-bell-shake {
  animation: cs-bell-shake 5s 12 ease-in-out;
  transform-origin: center top;
  will-change: transform;
}

@keyframes cs-bell-shake {
  0% {
    transform: rotate(0);
  }

  5% {
    transform: rotate(-20deg);
  }

  11% {
    transform: rotate(20deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  25% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(0deg);
  }
}

@keyframes like-pulse {
  0% {
    box-shadow: 0 0 0 0 lighten($like-btn-colour, 10%);
  }

  100% {
    box-shadow: 0 0 0 1rem rgba($like-btn-colour, 0);
  }
}
