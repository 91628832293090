.data-modified {
  border-color: $cs-covered;
  background: $cs-pale-covered;

  label {
    border-color: $cs-covered;
    background: $cs-pale-covered;
  }

  textarea {
    border-color: $cs-covered;
    background: $cs-pale-covered;
  }
}
