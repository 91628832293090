.long-term-plan-output {
  page: landscape;

  .cycle-project-title {
    font-size: 0.9rem;
  }

  &.mode-pdf {
    .cycle-project {
      //.col-2 seems to wrap in docraptor annoyingly
      flex: 0 1 auto !important;
      max-width: 16.65%;
    }
  }
}
