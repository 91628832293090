$btn-padding-y-tiny: 0.1rem !default;
$btn-padding-x-tiny: 0.25rem !default;
$btn-line-height-tiny: 1.25 !default;
$btn-font-size-tiny: ($font-size-base * .7) !default;
$btn-border-radius-tiny: $border-radius-sm !default;

.btn-link {
  font-family: $font-family-base;
}

.btn-tiny {
  @include button-size($btn-padding-y-tiny, $btn-padding-x-tiny, $btn-font-size-tiny, $btn-line-height-tiny, $btn-border-radius-tiny
  );
}

.btn-xl,
.btn-group-xl > .btn {
  padding: #{$spacer * 0.75} #{$spacer * 1.5};
  font-size: 1.75rem;
  line-height: 1.5;
  border-radius: $border-radius-lg;
}

.btn-square {
  height: $input-height;
  width: $input-height;
  padding: $btn-padding-y;

  &.btn-tiny {
    height: 1.1rem;
    width: 1.1rem;
    padding: $btn-padding-y-tiny;
  }

  &.btn-sm {
    height: $input-height-sm;
    width: $input-height-sm;
    padding: $btn-padding-y-sm;
  }

  &.btn-lg {
    height: $input-height-lg;
    width: $input-height-lg;
    padding: $btn-padding-y-lg;
  }
}

.btn-no-style {
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.btn-link {
  @each $name, $color in $theme-colors {
    &-#{$name} {
      background: transparent;
      border-color: transparent;
      color: $color;
      padding: 0.25rem 0.5rem;
      font-size: 0.765625rem;
      line-height: 1.2;
    }
  }
}

.button-as-dropdown-button {
  background-color: transparent;
  border-width: 0;
  color: black !important;
  width: 100%;
  text-align: left;

  &:hover {
    background-color: $cs-pale-grey;
  }
}

@each $name, $colour in $assessments {
  .btn-#{$name} {
    @include button-variant($colour, $colour);
  }

  .btn-outline-#{$name} {
    @include button-outline-variant($colour);
  }
}

@each $name, $colour in $curriculum {
  .btn-#{$name} {
    @include button-variant($colour, $colour);
  }

  .btn-outline-#{$name} {
    @include button-outline-variant($colour);
  }
}
