.shake-error {
  animation: shakeError 75ms 5 ease-in alternate;
}

@keyframes shakeError {
  0% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0px);
  }
}
