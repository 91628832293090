.pos-coverage-symbol {
  background: $cs-innovate;
  color: $white;
}

.pos-breadth-symbol {
  background: $cs-develop;
  color: $white;
}

.pos-discrete-symbol {
  background: $cs-develop;
  color: $white;
}

.pos-coverage-symbol-outline {
  color: $cs-innovate;
}

.pos-breadth-symbol-outline {
  color: $cs-develop;
}

.pos-discrete-symbol-outline {
  color: $cs-develop;
}
