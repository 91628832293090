.project-memorable-experience {}

.activity-english-pack-box {
  background: $gray-300;
}

.activity-instructions-box,
.activity-assessment-box {
  border-left: 8px solid $cs-innovate;
}

.activity-instructions-container,
.activity-assessment-container {
  border-color: $cs-innovate;
}
