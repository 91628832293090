.table-layout-fixed {
  table-layout: fixed;
}

.table {
  thead tr:first-child th {
    border-top: none;
  }
}

.table.table-short {
  width: auto;

  th, td {
    padding-right: $spacer * 2;
  }

  th:last-child, td:last-child {
    padding-right: $table-cell-padding;
  }
}
