.project-subject-widget {
  ul {
    min-width: 15rem;
  }

  .fixed-text {
    flex: 1 0 60px;
    margin-right: $spacer * 0.25;
  }
}
