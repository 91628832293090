.pupil-report-output {
  @media screen {
    margin: $spacer;
  }

  .school-logo {
    max-width: 150px;
    max-height: 150px;
    width: auto;
  }

  .report-title {
    text-decoration: underline;
  }

  .progress-arrow {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    font-size: 0.8rem;
    width: 1.2rem;

    &.lg {
      font-size: 1.2rem;
      width: 1.4rem;

    }
  }
}
