.entitlement-and-enrichment-edit {
  .ee-table {
    thead th {
      min-width: 7.5rem;
      width: 20%;
    }

    .group-col {
      width: 8rem;
    }

    @include media-breakpoint-down(xs) {
      td.add-section-col {
        display: none !important;
      }
    }

    @include media-breakpoint-up(xs) {
      .add-section-col {
        min-width: 0;
      }
    }
  }
}
